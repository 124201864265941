// ** Auth Endpoints
export default {
	loginEndpoint: `https://bhysterestoque.devnx.com.br/api/auth/login`,
	registerEndpoint: `https://bhysterestoque.devnx.com.br/api/auth/register`,
	refreshEndpoint: `https://bhysterestoque.devnx.com.br/api/auth/refresh-token`,
	logoutEndpoint: `https://bhysterestoque.devnx.com.br/api/auth/logout`,

	// ** This will be prefixed in authorization header with token
	// ? e.g. Authorization: Bearer <token>
	tokenType: 'Bearer',

	// ** Value of this property will be used as key to store JWT token in storage
	storageTokenKeyName: 'accessToken',
	storageRefreshTokenKeyName: 'refreshToken'
}
